/*
Created by esoda
Created on Nov, 2023
Contact esoda.id
*/

import React from "react";
import "styles/PageLayout.css";
import {
  IconButton,
  Drawer,
  Avatar,
  Collapse,
  Container,
  Box,
} from "@mui/material";
import Icon from "@mui/material/Icon";
import { updateMenuUser } from "store";
import { clearSession } from "store";
import Constants from "utils/Constant";
import { userAuth } from "store";
import ModalAccount from "./ModalAccount";
import { setUserAuth } from "store";

const drawerWidth = "280px";

const ARR_MENU = [
  // {
  //   label: "Beranda",
  //   link: "/dashboard",
  //   icon: "home",
  //   haveChild: false,
  //   active: true,
  //   openCollapse: false,
  //   child: [],
  // },
  {
    label: "Beranda",
    link: "/new-dashboard",
    icon: "home",
    haveChild: false,
    active: true,
    openCollapse: false,
    child: [],
  },
  {
    label: "Data Merchant",
    link: "/merchant",
    icon: "business",
    haveChild: false,
    active: false,
    openCollapse: false,
    child: [],
  },
  // {
  //   label: "Data Webhook",
  //   link: "/sync-data",
  //   icon: "webhook",
  //   haveChild: false,
  //   active: false,
  //   openCollapse: false,
  //   child: [],
  // },
  {
    label: "Data Transaksi",
    link: "/transactions",
    icon: "webhook",
    haveChild: false,
    active: false,
    openCollapse: false,
    child: [],
  },
];

export default class PageLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      arrMenu: ARR_MENU,
      userData: null,
      openDrawer: false,
      anchorElUser: null,
      pathname: "",
      activeMenuName: "",
    };

    this._FirstMount = true;
  }
  componentDidMount() {
    if (this._FirstMount) {
      this._FirstMount = false;
      this.setActiveMenuListener();
      let userData = userAuth();
      this.setState({ userData });
    }
  }

  // ====================================================================================
  // ========== INITIALIZE, GET DATA ====================================================
  // ====================================================================================
  setActiveMenuListener = async () => {
    let pathname = window.location.pathname;
    this.setState({ pathname: pathname });
    const arrData = this.state.arrMenu;
    let activeMenuName = "";
    let arrMenu = arrData.map((item) => {
      item.active = false;
      item.openCollapse = false;
      if (item.link === pathname) {
        item.active = true;
        activeMenuName = item.label;
      } else {
        item.child = item.child.map((child) => {
          child.active = false;
          if (child.link === pathname) {
            item.active = true;
            item.openCollapse = true;
            child.active = true;
            activeMenuName = child.label;
          }
          return child;
        });
      }
      return item;
    });
    updateMenuUser(arrMenu);
    this.setState({ arrMenu, activeMenuName });
    if (this.props.menuActive) {
      this.props.menuActive(activeMenuName);
    }
  };

  // ====================================================================================
  // ========== ACTION LISTENERS ========================================================
  // ====================================================================================
  onClickDrawerMenuListener = () => {
    this.setState({ openDrawer: !this.state.openDrawer });
  };
  onOpenUserMenuListener = (e) => {
    this.setState({ anchorElUser: e.currentTarget });
  };
  onCloseUserMenuListener = () => {
    this.setState({ anchorElUser: null });
  };
  onLogoutListeners = async () => {
    clearSession();
    window.location.reload();
  };
  onClickMenuListener = (index, subIndex = null) => {
    let arrMenu = this.state.arrMenu;
    let item = arrMenu[index];
    if (item.haveChild) {
      if (subIndex === null) {
        arrMenu[index].openCollapse = !arrMenu[index].openCollapse;
      } else {
        if (arrMenu[index].child[subIndex].link !== "#") {
          arrMenu[index].active = true;
          arrMenu[index].child[subIndex].active = true;
          window.location.href = window.location.origin + arrMenu[index].child[subIndex].link
          // this.gotoLink(arrMenu[index].child[subIndex].link)
        }
      }
    } else {
      if (arrMenu[index].link !== "#") {
        arrMenu[index].active = true;
        window.location.href = window.location.origin + arrMenu[index].link
        // this.gotoLink(arrMenu[index].link)
      }
    }
    this.setState({ arrMenu });
  }

  // ====================================================================================
  // ========== RENDER SECTIONS =========================================================
  // ====================================================================================
  render() {
    return (
      <Container maxWidth={false} style={{ padding: 0 }}>
        {/* ==== DRAWER IN LARGE VIEW*/}
        <Drawer
          variant="permanent"
          sx={{
            display: {
              xs: "none",
              sm: "none",
              md: "block",
            },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              backgroundColor: "transparent",
              backgroundImage:
                "linear-gradient(167.46deg,rgba(147, 52, 246, 1) -1%,rgba(29, 33, 124, 1) 100%)",
              color: "#fff",
            },
          }}
          open
        >
          {this.renderAccountInformation()}
          {this.renderDrawerItems()}
          {this.renderLogout()}
        </Drawer>

        {/* ==== DRAWER IN MOBILE VIEW*/}
        <Drawer
          variant="temporary"
          anchor="left"
          open={this.state.openDrawer}
          onClose={this.onClickDrawerMenuListener}
          style={{ zIndex: 5000 }}
          ModalProps={{ keepMounted: true }}
          sx={{
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              backgroundColor: "transparent",
              backgroundImage:
                "linear-gradient(167.46deg,rgba(147, 52, 246, 1) -1%,rgba(29, 33, 124, 1) 100%)",
              color: "#fff",
            },
          }}
        >
          {this.renderAccountInformation()}
          {this.renderDrawerItems()}
          {this.renderLogout()}
        </Drawer>

        {/* ==== MAIN CONTENTS */}
        <Box
          component="main"
          className={"main"}
          sx={{
            marginLeft: { md: drawerWidth, xl: drawerWidth },
            width: { md: `calc(100% - ${drawerWidth}px)` },
            marginTop: "55px",
          }}
        >
          {/* ==== TOOLBAR*/}
          <div className={"toolbars"}>
            <div className={"menu"}>
              <IconButton
                aria-label="toggle menu"
                onClick={this.onClickDrawerMenuListener}
                edge="start"
                children={
                  <Icon style={{ color: "#fff", fontSize: 28 }}>menu</Icon>
                }
              />
            </div>
            <div className={"placeholder"}>{this.state.activeMenuName}</div>
            <div className={"reload"}>
              <IconButton
                aria-label="toggle menu"
                onClick={() => {
                  window.location.reload();
                }}
                edge="end"
                children={
                  <Icon style={{ color: "#fff", fontSize: 24 }}>refresh</Icon>
                }
              />
            </div>
          </div>
          {this.props.children}
        </Box>

        {/* ==== ACCOUNT DIALOG */}
        <ModalAccount
          ref={(value) => this.ref_ModalAccount = value}
          onSubmit={ async (params) => { 
            let userData = this.state.userData
            userData.name = params.name
            userData.email = params.email
            userData.mobilephone = params.mobilephone
            userData.address = params.address
            this.setState({ userData })
            setUserAuth(userData)
          }}
        />
      </Container>
    );
  }
  renderAccountInformation() {
    if (this.state.userData !== null){
      return (
        <div
          className={"user_info"}
          style={{ width: "inherit" }}
          onClick={() => {
            // console.log(this.state.userData)
            this.setState({ openDrawer: false });
            this.ref_ModalAccount.onShowDialog(this.state.userData)
          }}
        >
          <Avatar
            alt={this.state.userData.name}
            src={
              this.state.userData.image_url || Constants.image_default.avatar
            }
            sx={{ width: "30px", height: "30px" }}
          />
          <div className={"right"}>
            <h4 className={`single_line font_family`}>
              {this.state.userData.name}
            </h4>
            <span
              className={`single_line font_family`}
              style={{ fontSize: 12, opacity: 0.6 }}
            >
              {this.state.userData.role_name !== ""
                ? this.state.userData.role_name
                : "-"}
            </span>
          </div>
          <Icon style={{ marginRight: -3 }}>trending_flat</Icon>
        </div>
      );
    }
  }
  renderDrawerItems() {
    let arrData = this.state.arrMenu;
    return arrData.map((item, index) => {
      return (
        <div
          key={`div-${index}`}
          style={{
            marginTop: index === 0 ? 55 : 0,
            marginBottom: arrData.length - 1 === index ? 45 : 0,
          }}
        >
          <div
            onClick={() => this.onClickMenuListener(index)}
            key={index}
            className={
              item.active
                ? `${"list_drawer"} ${"list_drawer_active"}`
                : "list_drawer"
            }
          >
            <div className={"title"}>
              <Icon>{item.icon}</Icon>
              <p>{item.label}</p>
            </div>
            {item.haveChild && (
              <span style={{ marginRight: -5 }}>
                <Icon>{item.openCollapse ? "expand_less" : "expand_more"}</Icon>
              </span>
            )}
          </div>
          {item.haveChild && (
            <Collapse
              in={item.openCollapse}
              timeout="auto"
              unmountOnExit
              style={{
                backgroundColor: item.active
                  ? "rgba(0, 0, 0, 0.05)"
                  : "transparent",
              }}
            >
              {item.child.map((child, cIndex) => {
                return (
                  <div
                    key={`${index}-${cIndex}`}
                    onClick={() => this.onClickMenuListener(index, cIndex)}
                    className={
                      child.active
                        ? `${"list_drawer_child"} ${"list_drawer_child_active"}`
                        : `${"list_drawer_child"}`
                    }
                  >
                    <p>{child.label}</p>
                  </div>
                );
              })}
            </Collapse>
          )}
        </div>
      );
    });
  }
  renderLogout() {
    return (
      <div
        className={"logout"}
        onClick={() => {
          this.onLogoutListeners();
        }}
      >
        <Icon>lock</Icon>
        <div className={"title"}>Logout</div>
        <Icon>trending_flat</Icon>
      </div>
    );
  }
}
