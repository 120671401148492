/*
Created by esoda
Created on Jan, 2024
Contact esoda.id
Last modified: -
    - Read changelog.md
*/

import React from "react";
import { Modal, ModalBody, ModalTitle, ModalFooter } from "components/Modal";
import {
  FormControl,
  InputAdornment,
  Select,
  MenuItem,
  OutlinedInput,
} from "@mui/material";
import Icon from "@mui/material/Icon";
import FilterDate from "components/DateFilter";
import ApiHelpers from "utils/ApiHelpers";

export default class ModalSyncDataFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      dataFilter: [],
      inputs: {
        client_id: "all",
        client_name: "all",
        invoice_datetime: {
          title: "Semua tanggal",
          start_date: "",
          end_date: "",
        },
        status: "all",
      },
      arrClient: [],
    };
  }

  componentDidMount(){
    this.getClient()
  }

  // ====================================================================================
  // ========== ACTION LISTENERS ========================================================
  // ====================================================================================
  onShowDialog = (dataFilter = []) => {
    let inputs = this.state.inputs;
    this.setState(
      {
        showModal: true,
        dataFilter,
        inputs,
      },
      this.getClient
    );
  };
  onCloseDialog = () => {
    this.setState(
      { showModal: false, dataFilter: [], },
      this.onResetInput
    );
  };
  onResetInput = () => {
    this.setState({
      inputs: {
        client_id: "all",
        invoice_datetime: {
          title: "Semua tanggal",
          start_date: "",
          end_date: "",
        },
        status: "all",
      },
    });
  };
  getClient = async () => {
    let arrClient = [];
    let response = await ApiHelpers.get("master/client", {
      pagination_bool: false,
      is_active_bool: true,
    });
    if (response.status === 200) {
      if (
        response.results.data !== undefined &&
        response.results.data.length > 0
      ) {
        arrClient = response.results.data;
      }
    }
    this.setState({ arrClient });
  };

  // ====================================================================================
  // ========== RENDER SECTIONS =========================================================
  // ====================================================================================
  render() {
    let mdlBtnColor = "#505050";
    let mdlBtnIcon = "check_circle";

    return (
      <>
        <Modal
          width={"calc(100% - 40px)"}
          show={this.state.showModal}
          onModalClose={this.onCloseDialog}
          btnCloseTitle={this.state.modalType !== "detail" ? "TIDAK" : "TUTUP"}
        >
          <ModalTitle>
            <span
              style={{ fontSize: 16, color: "#505050", fontWeight: "bold" }}
            >
              Filter Data
            </span>
          </ModalTitle>
          <ModalBody>{this.renderModalContents()}</ModalBody>

          <ModalFooter>
            <div
              className={"form_act_btn"}
              style={{
                height: 40,
                color: "#fff",
                backgroundColor: mdlBtnColor,
                padding: "0 10px",
                fontSize: 14,
              }}
              onClick={() => {
                const { onSubmit } = this.props;
                if (onSubmit) {
                  let filter = [];
                  if (this.state.inputs.client_id !== "all") {
                    filter.push({
                      type: "string",
                      field: "client_id",
                      value: this.state.inputs.client_id,
                      comparison: "=",
                      title: this.state.inputs.client_name,
                    });
                  }

                  if (this.state.inputs.status !== "all") {
                    filter.push({
                      type: "string",
                      field: "status",
                      value: `${this.state.inputs.status}`,
                      comparison: "=",
                    });
                  }

                  if (this.state.inputs.invoice_datetime.title !== "Semua tanggal") {
                    filter.push({
                      type: "date",
                      field: "invoice_datetime",
                      value: `${this.state.inputs.invoice_datetime.start_date}::${this.state.inputs.invoice_datetime.end_date}`,
                      comparison: "bet",
                      title: this.state.inputs.invoice_datetime.title,
                    });
                  }
                  onSubmit(filter);
                }
              }}
            >
              <Icon style={{ fontSize: 16, marginRight: 7.5 }}>
                {mdlBtnIcon}
              </Icon>
              <div>FILTER DATA</div>
            </div>
          </ModalFooter>
        </Modal>

        {/* ROLE DIALOG */}
        <FilterDate
          ref={(value) => (this.ref_FilterDate = value)}
          onSelect={(params) => {
            let inputs = this.state.inputs;
            inputs.invoice_datetime.title = params.title;
            inputs.invoice_datetime.start_date = params.start_date;
            inputs.invoice_datetime.end_date = params.end_date;
            this.setState({ inputs });
          }}
        />
      </>
    );
  }
  renderModalContents() {
    return (
      <div style={{ paddingBottom: 10 }}>
        {/* TIPE PELANGGAN */}
        <FormControl fullWidth variant="outlined" style={{ marginTop: 5 }}>
          <span
            style={{
              textAlign: "left",
              fontSize: 12,
              color: "#666",
              marginBottom: 5,
            }}
          >
            Merchant
          </span>
          <Select
            id="input-category-title"
            className={""}
            startAdornment={
              <InputAdornment position="start">
                <Icon>account_box</Icon>
              </InputAdornment>
            }
            placeholder="Pilih tipe Pelanggan disini…"
            value={this.state.inputs.client_id}
            onChange={(event, child) => {
              let inputs = this.state.inputs;
              inputs.client_id = event.target.value;
              if (
                child !== undefined &&
                child.props !== undefined &&
                child.props.children !== undefined
              ) {
                inputs.client_name = child.props.children;
              }
              this.setState({ inputs });
            }}
            sx={{
              "& input::placeholder": { color: "#999" },
              backgroundColor: "#f7f7f7",
            }}
          >
            <MenuItem value={"all"}>Semua Merchant</MenuItem>
            {this.state.arrClient.length > 0 &&
              this.state.arrClient.map((item, index) => {
                return (
                  <MenuItem key={index} value={item.id}>
                    {item.name}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>

        {/* STATUS */}
        <FormControl fullWidth variant="outlined" style={{ marginTop: 5 }}>
          <span
            style={{
              textAlign: "left",
              fontSize: 12,
              color: "#666",
              marginBottom: 5,
            }}
          >
            Status Sync
          </span>
          <Select
            id="input-status"
            className={""}
            startAdornment={
              <InputAdornment position="start">
                <Icon>check_circle</Icon>
              </InputAdornment>
            }
            placeholder="Pilih status disini…"
            value={this.state.inputs.status}
            onChange={(event) => {
              let inputs = this.state.inputs;
              inputs.status = event.target.value;
              this.setState({ inputs });
            }}
            sx={{
              "& input::placeholder": { color: "#999" },
              backgroundColor: "#f7f7f7",
            }}
          >
            <MenuItem value={"all"}>Semua status</MenuItem>
            <MenuItem value={'pending'}>Pending</MenuItem>
            <MenuItem value={'selesai sebagian'}>Selesai Sebagian</MenuItem>
            <MenuItem value={'selesai'}>Selesai</MenuItem>
          </Select>
        </FormControl>

        {/* DATE TIME */}
        <FormControl fullWidth variant="outlined" style={{ marginTop: 5 }}>
          <span
            style={{
              textAlign: "left",
              fontSize: 12,
              color: "#666",
              marginBottom: 5,
            }}
          >
            Tanggal Transaksi
          </span>
          <OutlinedInput
            id="input-filter-date"
            className=""
            startAdornment={
              <InputAdornment position="start">
                <Icon>event</Icon>
              </InputAdornment>
            }
            endAdornment={
              <InputAdornment position="end">
                <Icon style={{ marginRight: -7.5 }}>arrow_drop_down</Icon>
              </InputAdornment>
            }
            placeholder="Pilih tanggal..."
            value={this.state.inputs.invoice_datetime.title}
            onClick={() => {
              this.ref_FilterDate.onSetData("Semua tanggal", "", "");
            }}
            sx={{
              "& input::placeholder": { color: "#999" },
              backgroundColor: "#f7f7f7",
            }}
          />
        </FormControl>
      </div>
    );
  }
}
