/*
Created by esoda
Created on Nov, 2023
Contact esoda.id
*/

const Constants = {
    appName: 'Accurate Webhook',
    baseUrlProd: 'https://kokapuravia.esoda.id',
    accurateWebhookBaseURL: 'https://kokapuravia-api.esoda.id/callback/webhook',
    Api: {
        devUrl: 'http://localhost:8080/',
    },
    image_default: {
        avatar: "https://t3.ftcdn.net/jpg/05/70/71/06/360_F_570710660_Jana1ujcJyQTiT2rIzvfmyXzXamVcby8.jpg",
        empty: "https://lpm.ulm.ac.id/image/desain/empty.jpg"
    }
}

export default Constants