import React from "react"
import CommonHelper from "utils/CommonHelper"
import { Box, Icon, Modal } from "@mui/material"
import moment from "moment"
import Highlight from "react-highlight"

export default class ModalSyncDataDetail extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showModal: false,
      dataParse: null,
    }
    this.firstMount = true
  }

  componentDidMount() {
    if (this.firstMount) {
      this.firstMount = false
    }
  }

  componentWillUnmount() {}

  // ====================================================================================
  // ========== INITIALIZE, GET DATA ====================================================
  // ====================================================================================

  // ====================================================================================
  // ========== ACTION LISTENERS ========================================================
  // ====================================================================================
  onShowDialog = (dataParse) => {
    this.setState({ showModal: true, dataParse })
  }
  onCloseDialog = () => {
    this.setState({ showModal: false, dataParse: null })
  }
  // ====================================================================================
  // ========== RENDER SECTION ==========================================================
  // ====================================================================================
  render() {
    return (
      <Modal open={this.state.showModal} onClose={this.onCloseDialog}>
        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "100wh",
            height: "100vh",
          }}
        >
          <div className={`my_modal_detail_container`}>
            <div className={"toolbar"}>
              <div className={"title"}>Detail Data Webhook</div>
              <div className={"close"} onClick={this.onCloseDialog}>
                <Icon color="primary">close</Icon>
              </div>
            </div>
            <div className={"content"}>
              <div className={"flex"}>
                <div className={"left"}>{this.renderLeftContent()}</div>
                <div className={"right"}>{this.renderRightContent()}</div>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    )
  }

  renderLeftContent() {
    let objData = this.state.dataParse
    if (objData !== null) {
      let backgroundColor = "rgba(230, 126, 34, 0.25)";
      let color = "rgba(230, 126, 34, 1)";
      if (objData.status === "selesai") {
        backgroundColor = "rgba(22, 160, 133, 0.25)";
        color = "rgba(22, 160, 133, 1)";
      }
      if (objData.status === "selesai sebagian") {
        backgroundColor = "rgba(192, 57, 43, 0.25)";
        color = "rgba(192, 57, 43, 1)";
      }
      return (
        <>
          <h5
            style={{
              marginBottom: 20,
              textAlign: "center",
              backgroundColor: "rgba(0,0,0,0.1)",
              borderRadius: 5,
              padding: "10px 0",
              textTransform: "uppercase",
            }}
          >
            Informasi Umum
          </h5>
          <div className={"label_flex_row"} style={inlineStyle.rows}>
            <div>Nama Merchant</div>
            <div className={"label_right"}>{objData.client_name}</div>
          </div>
          <div className={"label_flex_row"} style={inlineStyle.rows}>
            <div>Nomor Invoice</div>
            <div className={"label_right"}>{objData.invoice_number}</div>
          </div>
          <div className={"label_flex_row"} style={inlineStyle.rows}>
            <div>Tanggal Transaksi</div>
            <div className={"label_right"}>
              {moment(objData.invoice_datetime).format("lll")}
            </div>
          </div>
          <div className={"label_flex_row"} style={inlineStyle.rows}>
            <div>Jumlah Produk</div>
            <div className={"label_right"}>
              {CommonHelper.formatNumber(objData.item_count)}
            </div>
          </div>
          <div className={"label_flex_row"} style={inlineStyle.rows}>
            <div>Nominal Transaksi</div>
            <div className={"label_right"}>
              {CommonHelper.formatNumber(objData.grand_total, "idr")}
            </div>
          </div>
          <div className={"label_flex_row"} style={inlineStyle.rows}>
            <div>Nominal Transaksi yang di Sync</div>
            <div className={"label_right"}>
              {CommonHelper.formatNumber(
                objData.sync_grand_total,
                "idr"
              )}
            </div>
          </div>
          <div
            className={"label_flex_row"}
            style={{ ...inlineStyle.rows, borderBottom: "none" }}
          >
            <div>Status Sync</div>
            <div>
              <span
                style={{
                  backgroundColor,
                  color,
                  fontWeight: "bold",
                  padding: "5px 10px",
                  borderRadius: 20,
                  fontSize: 13,
                  textTransform: "uppercase",
                }}
              >
                {objData.status}
              </span>
            </div>
          </div>
        </>
      )
    }
  }
  renderRightContent(){
    let objData = this.state.dataParse
    if(objData !== null){
      if(objData.item_array.length > 0){
        return (
          <>
            <h5
              style={{
                marginBottom: 20,
                textAlign: "center",
                backgroundColor: "rgba(0,0,0,0.1)",
                borderRadius: 5,
                padding: "10px 0",
                textTransform: "uppercase",
              }}
            >
              PRODUK
            </h5>
            {objData.item_array.map( (item, index) => {
              let backgroundColor = "rgba(230, 126, 34, 0.25)";
              let color = "rgba(230, 126, 34, 1)";
              if (item.transaction_item_status === "selesai") {
                backgroundColor = "rgba(22, 160, 133, 0.25)";
                color = "rgba(22, 160, 133, 1)";
              }
              if (item.transaction_item_status === "selesai sebagian") {
                backgroundColor = "rgba(192, 57, 43, 0.25)";
                color = "rgba(192, 57, 43, 1)";
              }
              return (
                <div style={{
                  display: 'flex',
                  flexDirection: 'column',
                  paddingBottom: 10,
                  marginBottom: 10,
                  borderBottom: index < objData.item_array.length - 1 ? '1px solid rgba(0,0,0,0.1)' : 'none',
                }}>
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'start', justifyContent: 'space-between' }}>
                    <div>
                      <p style={{ fontWeight: 'bold' }}>{item.transaction_item_name} - {item.transaction_item_sku}</p>
                      <p style={{ fontWeight: 'bold' }}>{item.transaction_item_qty} {item.transaction_item_unit}</p>
                    </div>
                    <div style={{ textTransform: "uppercase", fontSize: 11, marginTop: 5 }}>
                      <span
                        style={{
                          backgroundColor,
                          color,
                          fontWeight: "bold",
                          padding: "5px 10px",
                          borderRadius: 20,
                        }}
                      >
                        {item.transaction_item_status}
                      </span>
                    </div>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'end', justifyContent: 'space-between' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end', justifyContent: 'space-between', width: '100%' }}>
                      <p style={{ flex: 1, fontSize: 14, textAlign: 'left' }}></p>
                      <p style={{ flex: 1, fontSize: 14, textAlign: 'right' }}>Nominal Asli</p>
                      <p style={{ flex: 1, fontSize: 14, textAlign: 'right' }}>Nominal Sync</p>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end', justifyContent: 'space-between', width: '100%' }}>
                      <p style={{ flex: 1, fontSize: 14, textAlign: 'left' }}>Harga Satuan</p>
                      <p style={{ flex: 1, textAlign: 'right', fontWeight: 'bold' }}>{CommonHelper.formatNumber(item.transaction_item_price, 'idr')}</p>
                      <p style={{ flex: 1, textAlign: 'right', fontWeight: 'bold' }}>{CommonHelper.formatNumber(item.transaction_item_sync_price, 'idr')}</p>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end', justifyContent: 'space-between', width: '100%' }}>
                      <p style={{ flex: 1, fontSize: 14, textAlign: 'left' }}>Harga Sync</p>
                      <p style={{ flex: 1, textAlign: 'right', fontWeight: 'bold' }}>{CommonHelper.formatNumber(item.transaction_item_total_price, 'idr')}</p>
                      <p style={{ flex: 1, textAlign: 'right', fontWeight: 'bold' }}>{CommonHelper.formatNumber(item.transaction_item_sync_total_price, 'idr')}</p>
                    </div>
                  </div>
                </div>
              )
            })}
          </>
        )
      }
    }
  }
}

const inlineStyle = {
  rows: {
    fontSize: 15,
    borderBottom: "solid 1px rgba(0,0,0,0.1)",
    paddingBottom: 12,
    marginBottom: 12,
  },
}