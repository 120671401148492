import Constants from "./Constant"

const baseUrl = () => {
    let host = window.location.origin
    if(!host.includes('localhost') && !host.includes('//10.' && !host.includes('//192.168.'))){
        return `${window.location.origin}/api/`
    }else{
        return Constants.Api.devUrl
    }
}
const uploadImageUrl = 'https://cdn.esoda.id/image.php'

const getHeader = async () => {
    return {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    }
}

const generateParams = (params) => {
    let query = '';
    for (let key in params) {
        if (!params.hasOwnProperty(key)) continue;
        if (typeof params[key] === 'object') {
            params[key].forEach((item, index) => {
                for (let keyItem in item) {
                    query += `${key}[${index}][${keyItem}]=${encodeURI(
                        item[keyItem],
                    )}&`;
                }
            });
        } else {
            query += `${key}=${encodeURIComponent(params[key])}&`;
        }
    }
    return query === '' ? '' : `?${query.replace(/&+$/, '')}`;
}

const logOut = async () => {
    localStorage.setItem("user_session", null)
    window.location.reload()
}

const ApiHelpers = {
    get: async (url, params) => {
        let Header = await getHeader();
        return new Promise((resolve) => {
            let uri = baseUrl() + url + generateParams(params);
            fetch(uri, {
                method: 'GET',
                credentials:"include",
                headers: {
                    ...Header,
                },
            })
            .then((response) => { return response.json() })
            .then((responseData) => {
                if (responseData.status === 401) {
                    logOut();
                } else {
                    if (responseData.status === 404) {
                        return resolve({
                            status: 404,
                            message: 'Page Not Found.',
                        });
                    } else {
                        return resolve(responseData);
                    }
                }
            })
            .catch((error) => {
                return resolve({
                    status: 500,
                    message: 'Terjadi Kesalahan Server.',
                });
            });
        });
    },
    post: async (url, body) => {
        let Header = await getHeader();
        return new Promise((resolve) => {
            fetch(baseUrl() + url, {
                method: 'POST',
                credentials:"include",
                headers: {
                    ...Header,
                },
                body: JSON.stringify(body),
            })
            .then((response) => { return response.json() })
            .then((responseData) => {
                if (responseData.status === 401) {
                    logOut();
                } else {
                    if (responseData.status === 404) {
                        return resolve({
                            status: 404,
                            message: 'Page Not Found.',
                        });
                    } else {
                        return resolve(responseData);
                    }
                }
            })
            .catch((error) => {
                return resolve({
                    status: 500,
                    message: 'Terjadi Kesalahan Server.',
                });
            });
        });
    },
    uploadImage: async (body) => {
        return new Promise((resolve) => {
            fetch(uploadImageUrl, {
                method: 'POST',
                body: body,
            })
            .then((response) => { return response.json() })
            .then((responseData) => {
                return resolve(responseData);
            })
            .catch((error) => {
                return resolve({
                    status: 500,
                    message: 'Terjadi Kesalahan Server.',
                });
            });
        });
    },
}

export default ApiHelpers;